// 
// backgrounds.scss
//


@each $color,
$value in $theme-colors {
    .bg-soft-#{$color} {
        background-color: rgba(($value), 0.2) !important;
    }   
}

.bg-primary-subtle 
 text-primary {
    background-color: rgba(var(--bs-primary-rgb), 0.2)!important;
}