// 
// _dropdown.scss
// 

.dropdown-menu {
  box-shadow: $dropdown-box-shadow;
  animation-name: DropDownSlide;
  animation-duration: .3s;
  animation-fill-mode: both;
}


@keyframes DropDownSlide {
  100% {
      margin-top: -1px;
  }
  0% {
      margin-top: 8px;
  }
}


@media (min-width: 600px) {
  .dropdown-menu-xl {
    width: 420px;
  }

  .dropdown-menu-lg {
    width: 320px;
  }

  .dropdown-menu-md {
    width: 240px;
  }
}